var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-cascader-wrapper" },
    [
      _c("el-cascader", {
        ref: "cc",
        attrs: {
          props: _vm.props,
          placeholder: "全部",
          "popper-class": "a-cascader-poper",
          "show-all-levels": false,
          clearable: "",
        },
        on: { change: _vm.change },
        model: {
          value: _vm.areaIds,
          callback: function ($$v) {
            _vm.areaIds = $$v
          },
          expression: "areaIds",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }